@charset "UTF-8";
/*
 * cthor.me
 */
@font-face {
  font-family: "SourceSerif";
  src: url("/fonts/SourceSerifPro-Regular-Core.ttf.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: fallback;
  unicode-range: U+0020-007E, U+00A7, U+00AC, U+00B0, U+00B2, U+00B3, U+00B7, U+00B9, U+00BB, U+2013-2014, U+2018, U+2019, U+201C, U+201D, U+2020-2022, U+2026, U+2039, U+203A, U+2070, U+2074-2079, U+2212; }

@font-face {
  font-family: "SourceSerif";
  src: url("/fonts/SourceSerifPro-Regular-Extra.ttf.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: fallback;
  unicode-range: U+00A6, U+00A9, U+00AD, U+00AE, U+00B1, U+00B4-00B6, U+00D7, U+00F7, U+02DA, U+02DC, U+2012, U+2015, U+2032, U+2033, U+2044, U+2080-2089, U+221E, U+2248, U+2260, U+2264-2265, U+25C9, U+25CA, U+2610, U+2611, U+266A, U+2713; }

@font-face {
  font-family: "SourceSerif";
  src: url("/fonts/SourceSerifPro-Semibold-Core.ttf.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: fallback;
  unicode-range: U+0020-007E, U+00A7, U+00AC, U+00B0, U+00B2, U+00B3, U+00B7, U+00B9, U+00BB, U+2013-2014, U+2018, U+2019, U+201C, U+201D, U+2020-2022, U+2026, U+2039, U+203A, U+2070, U+2074-2079, U+2212; }

@font-face {
  font-family: "SourceSerif";
  src: url("/fonts/SourceSerifPro-Semibold-Extra.ttf.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: fallback;
  unicode-range: U+00A6, U+00A9, U+00AD, U+00AE, U+00B1, U+00B4-00B6, U+00D7, U+00F7, U+02DA, U+02DC, U+2012, U+2015, U+2032, U+2033, U+2044, U+2080-2089, U+221E, U+2248, U+2260, U+2264-2265, U+25C9, U+25CA, U+2610, U+2611, U+266A, U+2713; }

@font-face {
  font-family: "SourceSerif";
  src: url("/fonts/SourceSerifPro-It-Core.ttf.woff") format("woff");
  font-weight: normal;
  font-style: italic;
  font-display: fallback;
  unicode-range: U+0020-007E, U+00A7, U+00AC, U+00B0, U+00B2, U+00B3, U+00B7, U+00B9, U+00BB, U+2013-2014, U+2018, U+2019, U+201C, U+201D, U+2020-2022, U+2026, U+2039, U+203A, U+2070, U+2074-2079, U+2212; }

@font-face {
  font-family: "SourceSerif";
  src: url("/fonts/SourceSerifPro-It-Extra.ttf.woff") format("woff");
  font-weight: normal;
  font-style: italic;
  font-display: fallback;
  unicode-range: U+00A6, U+00A9, U+00AD, U+00AE, U+00B1, U+00B4-00B6, U+00D7, U+00F7, U+02DA, U+02DC, U+2012, U+2015, U+2032, U+2033, U+2044, U+2080-2089, U+221E, U+2248, U+2260, U+2264-2265, U+25C9, U+25CA, U+2610, U+2611, U+266A, U+2713; }

@font-face {
  font-family: "SourceSerif";
  src: url("/fonts/SourceSerifPro-SemiboldIt-Core.ttf.woff") format("woff");
  font-weight: bold;
  font-style: italic;
  font-display: fallback;
  unicode-range: U+0020-007E, U+00A7, U+00AC, U+00B0, U+00B2, U+00B3, U+00B7, U+00B9, U+00BB, U+2013-2014, U+2018, U+2019, U+201C, U+201D, U+2020-2022, U+2026, U+2039, U+203A, U+2070, U+2074-2079, U+2212; }

@font-face {
  font-family: "SourceSerif";
  src: url("/fonts/SourceSerifPro-SemiboldIt-Extra.ttf.woff") format("woff");
  font-weight: bold;
  font-style: italic;
  font-display: fallback;
  unicode-range: U+00A6, U+00A9, U+00AD, U+00AE, U+00B1, U+00B4-00B6, U+00D7, U+00F7, U+02DA, U+02DC, U+2012, U+2015, U+2032, U+2033, U+2044, U+2080-2089, U+221E, U+2248, U+2260, U+2264-2265, U+25C9, U+25CA, U+2610, U+2611, U+266A, U+2713; }

@font-face {
  font-family: "SourceSans";
  src: url("/fonts/SourceSansPro-Regular-Core.ttf.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: fallback;
  unicode-range: U+0020-007E, U+00A7, U+00AC, U+00B0, U+00B2, U+00B3, U+00B7, U+00B9, U+00BB, U+2013-2014, U+2018, U+2019, U+201C, U+201D, U+2020-2022, U+2026, U+2039, U+203A, U+2070, U+2074-2079, U+2212; }

@font-face {
  font-family: "SourceSans";
  src: url("/fonts/SourceSansPro-Regular-Extra.ttf.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: fallback;
  unicode-range: U+00A6, U+00A9, U+00AD, U+00AE, U+00B1, U+00B4-00B6, U+00D7, U+00F7, U+02DA, U+02DC, U+2012, U+2015, U+2032, U+2033, U+2044, U+2080-2089, U+221E, U+2248, U+2260, U+2264-2265, U+25C9, U+25CA, U+2610, U+2611, U+266A, U+2713; }

@font-face {
  font-family: "SourceSans";
  src: url("/fonts/SourceSansPro-Semibold-Core.ttf.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: fallback;
  unicode-range: U+0020-007E, U+00A7, U+00AC, U+00B0, U+00B2, U+00B3, U+00B7, U+00B9, U+00BB, U+2013-2014, U+2018, U+2019, U+201C, U+201D, U+2020-2022, U+2026, U+2039, U+203A, U+2070, U+2074-2079, U+2212; }

@font-face {
  font-family: "SourceSans";
  src: url("/fonts/SourceSansPro-Semibold-Extra.ttf.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: fallback;
  unicode-range: U+00A6, U+00A9, U+00AD, U+00AE, U+00B1, U+00B4-00B6, U+00D7, U+00F7, U+02DA, U+02DC, U+2012, U+2015, U+2032, U+2033, U+2044, U+2080-2089, U+221E, U+2248, U+2260, U+2264-2265, U+25C9, U+25CA, U+2610, U+2611, U+266A, U+2713; }

@font-face {
  font-family: "SourceSans";
  src: url("/fonts/SourceSansPro-It-Core.ttf.woff") format("woff");
  font-weight: normal;
  font-style: italic;
  font-display: fallback;
  unicode-range: U+0020-007E, U+00A7, U+00AC, U+00B0, U+00B2, U+00B3, U+00B7, U+00B9, U+00BB, U+2013-2014, U+2018, U+2019, U+201C, U+201D, U+2020-2022, U+2026, U+2039, U+203A, U+2070, U+2074-2079, U+2212; }

@font-face {
  font-family: "SourceSans";
  src: url("/fonts/SourceSansPro-It-Extra.ttf.woff") format("woff");
  font-weight: normal;
  font-style: italic;
  font-display: fallback;
  unicode-range: U+00A6, U+00A9, U+00AD, U+00AE, U+00B1, U+00B4-00B6, U+00D7, U+00F7, U+02DA, U+02DC, U+2012, U+2015, U+2032, U+2033, U+2044, U+2080-2089, U+221E, U+2248, U+2260, U+2264-2265, U+25C9, U+25CA, U+2610, U+2611, U+266A, U+2713; }

@font-face {
  font-family: "SourceSans";
  src: url("/fonts/SourceSansPro-SemiboldIt-Core.ttf.woff") format("woff");
  font-weight: bold;
  font-style: italic;
  font-display: fallback;
  unicode-range: U+0020-007E, U+00A7, U+00AC, U+00B0, U+00B2, U+00B3, U+00B7, U+00B9, U+00BB, U+2013-2014, U+2018, U+2019, U+201C, U+201D, U+2020-2022, U+2026, U+2039, U+203A, U+2070, U+2074-2079, U+2212; }

@font-face {
  font-family: "SourceSans";
  src: url("/fonts/SourceSansPro-SemiboldIt-Extra.ttf.woff") format("woff");
  font-weight: bold;
  font-style: italic;
  font-display: fallback;
  unicode-range: U+00A6, U+00A9, U+00AD, U+00AE, U+00B1, U+00B4-00B6, U+00D7, U+00F7, U+02DA, U+02DC, U+2012, U+2015, U+2032, U+2033, U+2044, U+2080-2089, U+221E, U+2248, U+2260, U+2264-2265, U+25C9, U+25CA, U+2610, U+2611, U+266A, U+2713; }

@font-face {
  font-family: "SourceCode";
  src: url("/fonts/SourceCodePro-Regular-Core.ttf.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: fallback;
  unicode-range: U+0020-007E, U+00A7, U+00AC, U+00B0, U+00B2, U+00B3, U+00B7, U+00B9, U+00BB, U+2013-2014, U+2018, U+2019, U+201C, U+201D, U+2020-2022, U+2026, U+2039, U+203A, U+2070, U+2074-2079, U+2212; }

@font-face {
  font-family: "SourceCode";
  src: url("/fonts/SourceCodePro-Regular-Extra.ttf.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: fallback;
  unicode-range: U+00A6, U+00A9, U+00AD, U+00AE, U+00B1, U+00B4-00B6, U+00D7, U+00F7, U+02DA, U+02DC, U+2012, U+2015, U+2032, U+2033, U+2044, U+2080-2089, U+221E, U+2248, U+2260, U+2264-2265, U+25C9, U+25CA, U+2610, U+2611, U+266A, U+2713; }

@font-face {
  font-family: "SourceCode";
  src: url("/fonts/SourceCodePro-Bold-Core.ttf.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: fallback;
  unicode-range: U+0020-007E, U+00A7, U+00AC, U+00B0, U+00B2, U+00B3, U+00B7, U+00B9, U+00BB, U+2013-2014, U+2018, U+2019, U+201C, U+201D, U+2020-2022, U+2026, U+2039, U+203A, U+2070, U+2074-2079, U+2212; }

@font-face {
  font-family: "SourceCode";
  src: url("/fonts/SourceCodePro-Bold-Extra.ttf.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: fallback;
  unicode-range: U+00A6, U+00A9, U+00AD, U+00AE, U+00B1, U+00B4-00B6, U+00D7, U+00F7, U+02DA, U+02DC, U+2012, U+2015, U+2032, U+2033, U+2044, U+2080-2089, U+221E, U+2248, U+2260, U+2264-2265, U+25C9, U+25CA, U+2610, U+2611, U+266A, U+2713; }

@font-face {
  font-family: "SourceCode";
  src: url("/fonts/SourceCodePro-It-Core.ttf.woff") format("woff");
  font-weight: normal;
  font-style: italic;
  font-display: fallback;
  unicode-range: U+0020-007E, U+00A7, U+00AC, U+00B0, U+00B2, U+00B3, U+00B7, U+00B9, U+00BB, U+2013-2014, U+2018, U+2019, U+201C, U+201D, U+2020-2022, U+2026, U+2039, U+203A, U+2070, U+2074-2079, U+2212; }

@font-face {
  font-family: "SourceCode";
  src: url("/fonts/SourceCodePro-It-Extra.ttf.woff") format("woff");
  font-weight: normal;
  font-style: italic;
  font-display: fallback;
  unicode-range: U+00A6, U+00A9, U+00AD, U+00AE, U+00B1, U+00B4-00B6, U+00D7, U+00F7, U+02DA, U+02DC, U+2012, U+2015, U+2032, U+2033, U+2044, U+2080-2089, U+221E, U+2248, U+2260, U+2264-2265, U+25C9, U+25CA, U+2610, U+2611, U+266A, U+2713; }

@font-face {
  font-family: "SourceCode";
  src: url("/fonts/SourceCodePro-BoldIt-Core.ttf.woff") format("woff");
  font-weight: bold;
  font-style: italic;
  font-display: fallback;
  unicode-range: U+0020-007E, U+00A7, U+00AC, U+00B0, U+00B2, U+00B3, U+00B7, U+00B9, U+00BB, U+2013-2014, U+2018, U+2019, U+201C, U+201D, U+2020-2022, U+2026, U+2039, U+203A, U+2070, U+2074-2079, U+2212; }

@font-face {
  font-family: "SourceCode";
  src: url("/fonts/SourceCodePro-BoldIt-Extra.ttf.woff") format("woff");
  font-weight: bold;
  font-style: italic;
  font-display: fallback;
  unicode-range: U+00A6, U+00A9, U+00AD, U+00AE, U+00B1, U+00B4-00B6, U+00D7, U+00F7, U+02DA, U+02DC, U+2012, U+2015, U+2032, U+2033, U+2044, U+2080-2089, U+221E, U+2248, U+2260, U+2264-2265, U+25C9, U+25CA, U+2610, U+2611, U+266A, U+2713; }

:root {
  --sans: SourceSans, sans-serif;
  --serif: SourceSerif, serif;
  --mono: SourceCode, monospace;
  --fg: #111;
  --bg: #fff;
  --shadow: #888; }

html {
  /* Font clamp */
  font-size: 16px;
  font-size: calc(2.4vw + -1.6px);
  /* */
  font-family: var(--serif);
  text-size-adjust: 100%;
  overflow-y: scroll; }
  @media (min-width: 900px) {
    html {
      font-size: 20px; } }
  @media (max-width: 733.3333333333px) {
    html {
      font-size: 16px; } }
* {
  box-sizing: border-box; }

::selection {
  background: #dbfaff;
  text-shadow: none; }

body {
  background-color: var(--bg);
  padding: 0;
  margin: 0;
  color: var(--fg); }

main > *,
header,
.container {
  display: block;
  max-width: 45rem;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding: 0 0.5rem; }

main > script,
main > style {
  display: none; }

.xl {
  max-width: 48rem; }

.xxl {
  max-width: 51rem; }

.xxxl {
  max-width: 54rem; }

.xxxxl {
  max-width: 57rem; }

.xxxxxl {
  max-width: 60rem; }

header {
  margin: 0.5em auto 1em auto;
  display: grid;
  grid-template-columns: 2.5em 1fr;
  grid-template-rows: auto auto;
  grid-template-areas: "logo title" "logo metadata";
  grid-gap: 0.3em 0; }
  header .logo {
    grid-area: logo;
    height: 2.5em;
    width: 2.5em; }
    header .logo img {
      display: block;
      height: 100%;
      width: 100%; }

footer {
  padding: 0;
  height: 100vh; }
  footer .logo {
    display: block;
    margin: 0 auto;
    width: 7em;
    height: 7em;
    position: relative;
    top: 25%;
    transform: translateY(-50%);
    filter: blur(2px) opacity(7%); }
    footer .logo img {
      height: auto;
      width: 100%; }

.sidebar-container {
  padding-left: 14em; }

.sidebar {
  position: fixed;
  z-index: 103;
  top: 0;
  left: 0;
  height: 100vh;
  width: 14em;
  max-width: calc(100vw - 1.5em);
  border-right: 3px double currentColor;
  overflow-y: scroll;
  scrollbar-width: none; }
  .sidebar::-webkit-scrollbar {
    display: none; }

.sidebar-expand {
  display: none;
  position: fixed;
  z-index: 102;
  top: 0;
  background-color: var(--bg);
  box-shadow: 0 0 0.2em var(--shadow);
  border-radius: 0 0 1em 1em;
  padding: 0.2em 0.4em 0.4em 0.4em;
  cursor: pointer;
  user-select: none;
  transition: transform 0.2s ease-in-out;
  transform: translateY(0);
  left: calc(50% - 505px); }
  .sidebar-expand svg {
    display: block;
    width: auto;
    height: 2em; }
  @media (max-width: 1010px) {
    .sidebar-expand {
      left: calc(50% - 19em); } }
  @media (max-width: 45em) {
    .sidebar-expand {
      left: 3.5em; } }
  @media (max-width: 260px) {
    .sidebar-expand {
      left: calc(3.5em - 130px + 50vw); } }
  @media (max-width: 150px) {
    .sidebar-expand {
      left: 0; } }
.sidebar-shade {
  position: fixed;
  z-index: 101;
  left: 0;
  top: 0;
  height: 100vh;
  width: 100vw;
  background-color: var(--fg);
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.5s, visibility 0.5s; }

.expanded .sidebar-expand {
  transform: translateY(-120%); }

@media (max-width: 1200px) {
  .sidebar-container {
    padding-left: 0; }
  .sidebar {
    background: var(--bg);
    transition: transform 0.2s ease-in-out;
    transform: translateX(-100%); }
  .sidebar-expand {
    display: block; }
  .expanded .sidebar {
    transform: translateX(0); }
  .expanded .sidebar-shade {
    opacity: 0.2;
    visibility: visible; } }

.toc {
  list-style-type: none;
  font-size: 0.9em;
  font-family: var(--sans);
  padding: 1em 0.5em 1em 1em;
  line-height: 1; }
  .toc li + li {
    margin-top: 0.3em; }
  .toc li {
    position: relative; }
    .toc li::after {
      transition: color 0.2s;
      color: transparent;
      content: '\2014';
      font-size: 1rem;
      position: absolute;
      top: 50%;
      left: -1em;
      transform: translateY(-50%); }
  .toc li.current::after {
    color: #185abc; }

.toc-1 {
  padding-left: 1em; }

.toc-2 {
  padding-left: 2em; }

.toc-3 {
  padding-left: 3em; }

.title {
  grid-area: title;
  font-family: inherit;
  font-variant: initial;
  font-size: 2.2em;
  line-height: 1;
  margin: 0;
  padding: 0 0.5em;
  text-align: center; }

.metadata {
  grid-area: metadata;
  font-style: italic;
  text-align: end;
  font-size: 0.85em; }

@media (max-width: 540px) {
  header {
    grid-template-columns: 1fr !important;
    grid-template-rows: repeat(3, auto);
    grid-template-areas: "logo" "title" "metadata"; }
    header .logo {
      justify-self: center;
      margin-bottom: 0.2em; }
  .title {
    padding: 0; } }

a {
  color: inherit;
  text-decoration: none;
  transition: background-color 0.2s, border-color 0.2s; }
  a:visited {
    color: inherit; }

a:not(.logo):not(.h-anchor):not(.tab):hover {
  background-color: #f8eaea; }

a.internal {
  font-variant: small-caps; }

p a:not(.footnote-ref):not(.footnote-back):not(.tab):not(.internal)::before, li a:not(.footnote-ref):not(.footnote-back):not(.tab):not(.internal)::before, dl a:not(.footnote-ref):not(.footnote-back):not(.tab):not(.internal)::before, .move-child a:not(.footnote-ref):not(.footnote-back):not(.tab):not(.internal)::before {
  content: "«";
  color: #933; }

p a:not(.footnote-ref):not(.footnote-back):not(.tab):not(.internal)::after, li a:not(.footnote-ref):not(.footnote-back):not(.tab):not(.internal)::after, dl a:not(.footnote-ref):not(.footnote-back):not(.tab):not(.internal)::after, .move-child a:not(.footnote-ref):not(.footnote-back):not(.tab):not(.internal)::after {
  content: "»";
  color: #933; }

h1, h2 {
  font-family: var(--sans);
  margin-top: 1.6rem;
  margin-bottom: 0.3rem;
  font-variant: small-caps;
  font-weight: normal; }

h1 {
  font-size: 1.5em;
  text-align: end; }
  h1 .h-anchor {
    border-bottom: 3px double currentColor;
    flex-direction: row-reverse; }

h2 {
  font-size: 1.4em; }
  h2 .h-anchor {
    border-bottom: 1px dotted currentColor; }

h3, h4, h5, h6 {
  font-size: 1.05em;
  font-weight: bold;
  margin-top: 1em;
  margin-bottom: 0; }

dt:not(:first-child) {
  margin-top: 0.2em; }

dt {
  font-weight: bold; }

ol, ul {
  padding-left: 2em; }

pre code {
  font-family: var(--mono);
  font-size: 0.7em;
  display: block;
  overflow-x: auto;
  overflow-y: hidden; }
  pre code a::after {
    display: none; }

table {
  border-spacing: 0px;
  border-collapse: collapse;
  overflow-x: auto; }
  table .header th {
    border-bottom: 3px double currentColor; }
  table td, table th {
    padding: 0.1em 0.3em; }
  table tr:not(:first-child) td, table tr:not(:first-child) th {
    border-top: 1px solid currentColor; }
  table td:not(:first-child), table th:not(:first-child) {
    border-left: 1px solid currentColor; }

figure img {
  display: block;
  max-width: 100%; }

figure.center div, figure.wide div {
  overflow-x: auto; }

figure.center img, figure.wide img {
  height: 100%;
  width: auto;
  margin: 0 auto;
  max-width: none; }

figure.wide {
  max-width: none; }

video {
  display: block;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto; }

main > p, main > dl, main > pre, main > .sourceCode, main > table {
  padding-left: 1rem; }

main > ol, main > ul {
  padding-left: 2.5rem; }

p {
  margin-top: 0;
  margin-bottom: 0; }

script + p,
style + p {
  margin-top: 1em; }

p + p {
  margin-top: 1rem; }

p + .p {
  margin-top: 1rem; }

p + ol {
  margin-top: 1rem; }

p + ul {
  margin-top: 1rem; }

p + dl {
  margin-top: 1rem; }

p + pre {
  margin-top: 1rem; }

p + form {
  margin-top: 1rem; }

p + blockquote {
  margin-top: 1rem; }

p + table {
  margin-top: 1rem; }

p + figure {
  margin-top: 1rem; }

p + video {
  margin-top: 1rem; }

p + .sourceCode {
  margin-top: 1rem; }

p + .epigraph {
  margin-top: 1rem; }

.p {
  margin-top: 0;
  margin-bottom: 0; }

script + .p,
style + .p {
  margin-top: 1em; }

.p + p {
  margin-top: 1rem; }

.p + .p {
  margin-top: 1rem; }

.p + ol {
  margin-top: 1rem; }

.p + ul {
  margin-top: 1rem; }

.p + dl {
  margin-top: 1rem; }

.p + pre {
  margin-top: 1rem; }

.p + form {
  margin-top: 1rem; }

.p + blockquote {
  margin-top: 1rem; }

.p + table {
  margin-top: 1rem; }

.p + figure {
  margin-top: 1rem; }

.p + video {
  margin-top: 1rem; }

.p + .sourceCode {
  margin-top: 1rem; }

.p + .epigraph {
  margin-top: 1rem; }

ol {
  margin-top: 0;
  margin-bottom: 0; }

script + ol,
style + ol {
  margin-top: 1em; }

ol + p {
  margin-top: 1rem; }

ol + .p {
  margin-top: 1rem; }

ol + ol {
  margin-top: 1rem; }

ol + ul {
  margin-top: 1rem; }

ol + dl {
  margin-top: 1rem; }

ol + pre {
  margin-top: 1rem; }

ol + form {
  margin-top: 1rem; }

ol + blockquote {
  margin-top: 1rem; }

ol + table {
  margin-top: 1rem; }

ol + figure {
  margin-top: 1rem; }

ol + video {
  margin-top: 1rem; }

ol + .sourceCode {
  margin-top: 1rem; }

ol + .epigraph {
  margin-top: 1rem; }

ul {
  margin-top: 0;
  margin-bottom: 0; }

script + ul,
style + ul {
  margin-top: 1em; }

ul + p {
  margin-top: 1rem; }

ul + .p {
  margin-top: 1rem; }

ul + ol {
  margin-top: 1rem; }

ul + ul {
  margin-top: 1rem; }

ul + dl {
  margin-top: 1rem; }

ul + pre {
  margin-top: 1rem; }

ul + form {
  margin-top: 1rem; }

ul + blockquote {
  margin-top: 1rem; }

ul + table {
  margin-top: 1rem; }

ul + figure {
  margin-top: 1rem; }

ul + video {
  margin-top: 1rem; }

ul + .sourceCode {
  margin-top: 1rem; }

ul + .epigraph {
  margin-top: 1rem; }

dl {
  margin-top: 0;
  margin-bottom: 0; }

script + dl,
style + dl {
  margin-top: 1em; }

dl + p {
  margin-top: 1rem; }

dl + .p {
  margin-top: 1rem; }

dl + ol {
  margin-top: 1rem; }

dl + ul {
  margin-top: 1rem; }

dl + dl {
  margin-top: 1rem; }

dl + pre {
  margin-top: 1rem; }

dl + form {
  margin-top: 1rem; }

dl + blockquote {
  margin-top: 1rem; }

dl + table {
  margin-top: 1rem; }

dl + figure {
  margin-top: 1rem; }

dl + video {
  margin-top: 1rem; }

dl + .sourceCode {
  margin-top: 1rem; }

dl + .epigraph {
  margin-top: 1rem; }

pre {
  margin-top: 0;
  margin-bottom: 0; }

script + pre,
style + pre {
  margin-top: 1em; }

pre + p {
  margin-top: 1rem; }

pre + .p {
  margin-top: 1rem; }

pre + ol {
  margin-top: 1rem; }

pre + ul {
  margin-top: 1rem; }

pre + dl {
  margin-top: 1rem; }

pre + pre {
  margin-top: 1rem; }

pre + form {
  margin-top: 1rem; }

pre + blockquote {
  margin-top: 1rem; }

pre + table {
  margin-top: 1rem; }

pre + figure {
  margin-top: 1rem; }

pre + video {
  margin-top: 1rem; }

pre + .sourceCode {
  margin-top: 1rem; }

pre + .epigraph {
  margin-top: 1rem; }

form {
  margin-top: 0;
  margin-bottom: 0; }

script + form,
style + form {
  margin-top: 1em; }

form + p {
  margin-top: 1rem; }

form + .p {
  margin-top: 1rem; }

form + ol {
  margin-top: 1rem; }

form + ul {
  margin-top: 1rem; }

form + dl {
  margin-top: 1rem; }

form + pre {
  margin-top: 1rem; }

form + form {
  margin-top: 1rem; }

form + blockquote {
  margin-top: 1rem; }

form + table {
  margin-top: 1rem; }

form + figure {
  margin-top: 1rem; }

form + video {
  margin-top: 1rem; }

form + .sourceCode {
  margin-top: 1rem; }

form + .epigraph {
  margin-top: 1rem; }

blockquote {
  margin-top: 0;
  margin-bottom: 0; }

script + blockquote,
style + blockquote {
  margin-top: 1em; }

blockquote + p {
  margin-top: 1rem; }

blockquote + .p {
  margin-top: 1rem; }

blockquote + ol {
  margin-top: 1rem; }

blockquote + ul {
  margin-top: 1rem; }

blockquote + dl {
  margin-top: 1rem; }

blockquote + pre {
  margin-top: 1rem; }

blockquote + form {
  margin-top: 1rem; }

blockquote + blockquote {
  margin-top: 1rem; }

blockquote + table {
  margin-top: 1rem; }

blockquote + figure {
  margin-top: 1rem; }

blockquote + video {
  margin-top: 1rem; }

blockquote + .sourceCode {
  margin-top: 1rem; }

blockquote + .epigraph {
  margin-top: 1rem; }

table {
  margin-top: 0;
  margin-bottom: 0; }

script + table,
style + table {
  margin-top: 1em; }

table + p {
  margin-top: 1rem; }

table + .p {
  margin-top: 1rem; }

table + ol {
  margin-top: 1rem; }

table + ul {
  margin-top: 1rem; }

table + dl {
  margin-top: 1rem; }

table + pre {
  margin-top: 1rem; }

table + form {
  margin-top: 1rem; }

table + blockquote {
  margin-top: 1rem; }

table + table {
  margin-top: 1rem; }

table + figure {
  margin-top: 1rem; }

table + video {
  margin-top: 1rem; }

table + .sourceCode {
  margin-top: 1rem; }

table + .epigraph {
  margin-top: 1rem; }

figure {
  margin-top: 0;
  margin-bottom: 0; }

script + figure,
style + figure {
  margin-top: 1em; }

figure + p {
  margin-top: 1rem; }

figure + .p {
  margin-top: 1rem; }

figure + ol {
  margin-top: 1rem; }

figure + ul {
  margin-top: 1rem; }

figure + dl {
  margin-top: 1rem; }

figure + pre {
  margin-top: 1rem; }

figure + form {
  margin-top: 1rem; }

figure + blockquote {
  margin-top: 1rem; }

figure + table {
  margin-top: 1rem; }

figure + figure {
  margin-top: 1rem; }

figure + video {
  margin-top: 1rem; }

figure + .sourceCode {
  margin-top: 1rem; }

figure + .epigraph {
  margin-top: 1rem; }

video {
  margin-top: 0;
  margin-bottom: 0; }

script + video,
style + video {
  margin-top: 1em; }

video + p {
  margin-top: 1rem; }

video + .p {
  margin-top: 1rem; }

video + ol {
  margin-top: 1rem; }

video + ul {
  margin-top: 1rem; }

video + dl {
  margin-top: 1rem; }

video + pre {
  margin-top: 1rem; }

video + form {
  margin-top: 1rem; }

video + blockquote {
  margin-top: 1rem; }

video + table {
  margin-top: 1rem; }

video + figure {
  margin-top: 1rem; }

video + video {
  margin-top: 1rem; }

video + .sourceCode {
  margin-top: 1rem; }

video + .epigraph {
  margin-top: 1rem; }

.sourceCode {
  margin-top: 0;
  margin-bottom: 0; }

script + .sourceCode,
style + .sourceCode {
  margin-top: 1em; }

.sourceCode + p {
  margin-top: 1rem; }

.sourceCode + .p {
  margin-top: 1rem; }

.sourceCode + ol {
  margin-top: 1rem; }

.sourceCode + ul {
  margin-top: 1rem; }

.sourceCode + dl {
  margin-top: 1rem; }

.sourceCode + pre {
  margin-top: 1rem; }

.sourceCode + form {
  margin-top: 1rem; }

.sourceCode + blockquote {
  margin-top: 1rem; }

.sourceCode + table {
  margin-top: 1rem; }

.sourceCode + figure {
  margin-top: 1rem; }

.sourceCode + video {
  margin-top: 1rem; }

.sourceCode + .sourceCode {
  margin-top: 1rem; }

.sourceCode + .epigraph {
  margin-top: 1rem; }

.epigraph {
  margin-top: 0;
  margin-bottom: 0; }

script + .epigraph,
style + .epigraph {
  margin-top: 1em; }

.epigraph + p {
  margin-top: 1rem; }

.epigraph + .p {
  margin-top: 1rem; }

.epigraph + ol {
  margin-top: 1rem; }

.epigraph + ul {
  margin-top: 1rem; }

.epigraph + dl {
  margin-top: 1rem; }

.epigraph + pre {
  margin-top: 1rem; }

.epigraph + form {
  margin-top: 1rem; }

.epigraph + blockquote {
  margin-top: 1rem; }

.epigraph + table {
  margin-top: 1rem; }

.epigraph + figure {
  margin-top: 1rem; }

.epigraph + video {
  margin-top: 1rem; }

.epigraph + .sourceCode {
  margin-top: 1rem; }

.epigraph + .epigraph {
  margin-top: 1rem; }

.h-anchor {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  transition: background-color 0.2s; }
  .h-anchor::after {
    transition: opacity 0.2s, visibility 0.2s;
    content: "§";
    font-family: var(--serif);
    font-weight: normal;
    font-size: 0.8em;
    margin: 0 0.2em;
    opacity: 0;
    visibility: hidden;
    position: relative;
    margin: 0 0.2em;
    top: -0.07em; }
  .h-anchor:hover {
    background-color: #f2f2f2; }
    .h-anchor:hover::after {
      opacity: 1;
      visibility: visible; }

.footnote-ref + .footnote-ref {
  margin-left: 0.1em; }

.footnotes > li + li {
  margin-top: 1.4em; }

.footnotes > li {
  position: relative; }
  .footnotes > li .footnote-back {
    padding: 0 0.2em;
    margin: 0 0.2em; }

.hidden {
  display: none !important; }

code span.al {
  color: #ff0000;
  font-weight: bold; }

code span.an {
  color: #60a0b0;
  font-weight: bold;
  font-style: italic; }

code span.at {
  color: #7d9029; }

code span.bn {
  color: #40a070; }

code span.cf {
  color: #007020;
  font-weight: bold; }

code span.ch {
  color: #4070a0; }

code span.cn {
  color: #880000; }

code span.co {
  color: #60a0b0;
  font-style: italic; }

code span.cv {
  color: #60a0b0;
  font-weight: bold;
  font-style: italic; }

code span.do {
  color: #ba2121;
  font-style: italic; }

code span.dt {
  color: #902000; }

code span.dv {
  color: #40a070; }

code span.er {
  color: #ff0000;
  font-weight: bold; }

code span.fl {
  color: #40a070; }

code span.fu {
  color: #06287e; }

code span.in {
  color: #60a0b0;
  font-weight: bold;
  font-style: italic; }

code span.kw {
  color: #007020;
  font-weight: bold; }

code span.op {
  color: #666666; }

code span.ot {
  color: #007020; }

code span.pp {
  color: #bc7a00; }

code span.sc {
  color: #4070a0; }

code span.ss {
  color: #bb6688; }

code span.st {
  color: #4070a0; }

code span.va {
  color: #19177c; }

code span.vs {
  color: #4070a0; }

code span.wa {
  color: #60a0b0;
  font-weight: bold;
  font-style: italic; }

blockquote,
.epigraph {
  font-size: 0.95em;
  padding: 0.2em 0.5em 0.2em 2em;
  position: relative; }
  blockquote::before, blockquote::after,
  .epigraph::before,
  .epigraph::after {
    color: #555;
    position: absolute;
    font-size: 3em; }
  blockquote::before,
  .epigraph::before {
    line-height: 0;
    content: "\201C";
    top: 0.5em;
    left: 0.2em; }

.epigraph > :last-child {
  text-align: end;
  margin-right: 0;
  margin-top: 0; }
  .epigraph > :last-child::before {
    content: '\2015'; }

@supports (display: grid) {
  .cols-mm4 {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(4em, 1fr));
    grid-gap: 1em; }
    .cols-mm4 > * {
      margin: 0; }
  .cols-mm5 {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(5em, 1fr));
    grid-gap: 1em; }
    .cols-mm5 > * {
      margin: 0; }
  .cols-mm6 {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(6em, 1fr));
    grid-gap: 1em; }
    .cols-mm6 > * {
      margin: 0; }
  .cols-mm7 {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(7em, 1fr));
    grid-gap: 1em; }
    .cols-mm7 > * {
      margin: 0; }
  .cols-mm8 {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(8em, 1fr));
    grid-gap: 1em; }
    .cols-mm8 > * {
      margin: 0; }
  .cols-mm9 {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(9em, 1fr));
    grid-gap: 1em; }
    .cols-mm9 > * {
      margin: 0; }
  .cols-mm10 {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(10em, 1fr));
    grid-gap: 1em; }
    .cols-mm10 > * {
      margin: 0; }
  .cols-mm11 {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(11em, 1fr));
    grid-gap: 1em; }
    .cols-mm11 > * {
      margin: 0; }
  .cols-mm12 {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(12em, 1fr));
    grid-gap: 1em; }
    .cols-mm12 > * {
      margin: 0; }
  .cols-mm13 {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(13em, 1fr));
    grid-gap: 1em; }
    .cols-mm13 > * {
      margin: 0; }
  .cols-mm14 {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(14em, 1fr));
    grid-gap: 1em; }
    .cols-mm14 > * {
      margin: 0; }
  .cols-mm15 {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(15em, 1fr));
    grid-gap: 1em; }
    .cols-mm15 > * {
      margin: 0; }
  .cols-mm16 {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(16em, 1fr));
    grid-gap: 1em; }
    .cols-mm16 > * {
      margin: 0; } }

.tabs {
  list-style-type: none;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  font-family: var(--sans);
  padding: 0.01em; }
  .tabs + .tabs {
    margin-top: 0; }

.tab {
  display: block;
  background: none;
  border: none;
  border-bottom: 0.125em solid transparent;
  color: #222;
  cursor: pointer;
  font-size: inherit;
  margin: 0;
  padding: 0.3em 0.5em;
  outline: 0; }
  .tab.current {
    border-bottom-color: #8cd0f2; }
  .tab:not(.current):hover {
    border-bottom-color: #ccc; }

.switch {
  display: flex;
  justify-content: center;
  font-family: var(--sans);
  text-transform: uppercase;
  font-weight: bold;
  color: #333;
  font-size: 0.8em;
  line-height: 1.8em; }
  .switch label {
    width: 5em;
    cursor: pointer;
    overflow-x: hidden;
    border: 2px solid #555;
    border-radius: 1em; }
  .switch label:hover .switch-labels::after {
    background-color: #a8d8f0; }
  .switch .switch-labels::after {
    content: '';
    display: block;
    position: absolute;
    top: 0.2rem;
    right: 50%;
    width: 1em;
    height: 1em;
    background-color: #d9eaf2;
    border: 2px solid #555;
    border-radius: 1em;
    transition: background-color 0.1s, transform 0.3s;
    transform: translateX(-0.35em); }
  .switch .switch-labels {
    position: relative;
    display: grid;
    grid-template-columns: 50% 50%;
    align-items: center;
    width: 200%;
    transition: transform 0.3s;
    transform: translateX(0); }
    .switch .switch-labels span {
      padding: 0 0.3em; }
    .switch .switch-labels :first-child {
      text-align: end;
      padding-right: 1.8em; }
    .switch .switch-labels :last-child {
      padding-left: 1.8em; }
  .switch input[type="checkbox"] {
    display: none; }
  .switch input[type="checkbox"]:checked + .switch-labels {
    transform: translateX(-50%); }
    .switch input[type="checkbox"]:checked + .switch-labels::after {
      transform: translateX(1.6em); }

.index {
  list-style-type: none;
  padding-left: 1.5em;
  text-indent: -1.5em; }
